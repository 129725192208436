import {
  Button,
  CopyToClipboard,
  FlexRow,
  Form,
  LoadingEllipses,
  UnexpectedError,
} from "atoms";
import { useGenerateUserPassword } from "../api/useGeneratePassword";

type TempPasswordProps = {
  userId: string;
};

function TempPassword({ userId }: TempPasswordProps) {
  const { mutate, data, isLoading, isError, error } = useGenerateUserPassword();

  const handleCreateTempPassword = () => mutate(userId);

  return (
    <>
      <FlexRow gap="0 1rem" style={{ marginBottom: 10 }}>
        {data?.password &&
          (isLoading ? (
            <LoadingEllipses />
          ) : (
            <CopyToClipboard
              value={data.password}
              buttonText="Copy Password"
              variant="primary"
              size="xxs"
              successText="Password Copied"
            />
          ))}
        <Form>
          <Button
            size="xxs"
            variant={data?.password ? "blank" : "primary"}
            style={{ textTransform: "none" }}
            onClick={handleCreateTempPassword}
          >
            Generate New Password
          </Button>
        </Form>
      </FlexRow>

      {isError && <UnexpectedError error={error} />}
    </>
  );
}

export { TempPassword };

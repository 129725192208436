import { api } from "utils/api";
import { useMutation } from "react-query";

/**
 * API call to generate a random password for a user
 */
const generateUserPassword = (userId: string) =>
  api.post<{ password: string }>(`/admin/users/${userId}/password`, {
    body: JSON.stringify({ userId }),
  });

/**
 * Custom hook to get new password
 */
const useGenerateUserPassword = () => useMutation(generateUserPassword);

export { useGenerateUserPassword };
